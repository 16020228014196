import React, { useState } from 'react';

import hero7Image from '../assets/img/iconecwave/S7BannerContact.png';
import ParallaxBox from './partials/parallax-box';

const ReachUs = ({ translation }) => {

  const [state, setState] = useState({
    contact: {
      email: '',
      name: '',
      phone: '',
      desc: ''
    },
    inProgress: false,
    emailSent: false
  });

  const sendEmail = (e) => {

    e.preventDefault();

    setState({ ...state, inProgress: true });

    if (state.emailSent) {
      return;
    }

    fetch("https://eg9jxcmt31.execute-api.eu-west-1.amazonaws.com/test", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.contact)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setState({ inProgress: false, emailSent: true })
      });
  }

  const handleChange = (e) => {
    setState({ ...state, contact: { ...state.contact, [e.target.name]: e.target.value } })
  }

  const ParallaxBg = [
    {
      image: hero7Image,
      title: translation["S7Title"].label,
      description: translation["S7SubTitle"].label
    }
  ]

  return (
    <>
      <div id="contact">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>
      <section>
        <div className="container d-flex mobile-contact">
          <div className="col-lg-6">
            <div className="col col-md-12 col-xl-12 text-center text-lg-left f-size21">
              <address className="mb-4">
                <h5>{translation["S7Header1"].label}</h5>
                <p className="d-flex flex-column">
                  <span className="mb-3">{translation["S7Header1Text-1"].label}</span>
                  <span>{translation["S7Header1Text-2"].label}</span>
                </p>
              </address>
              <div className="d-flex flex-column justify-content-center justify-content-lg-start l-height">
                <div className="mx-3 mx-lg-0 mr-lg-5 mb-4">
                  <h5>{translation["S7Header2"].label}</h5>
                  <span>{translation["S7Header2Text"].label}</span>
                </div>
                <div className="mx-3 mx-lg-0 mr-lg-5 mb-4">
                  <h5>{translation["S7Header3"].label}</h5>
                  <span>{translation["S7Header3Text"].label}</span>
                </div>
                <div className="mx-3 mx-lg-0 mr-lg-5 mb-4">
                  <h5>{translation["S7Header4"].label}</h5>
                  <span>{translation["S7Header4Text"].label}</span>
                </div>
                <div className="mx-3 mx-lg-0 mr-lg-5 mb-4">
                  <h5>{translation["S7Header5"].label}</h5>
                  <span>{translation["S7Header5Text"].label}</span>
                </div>
                <div className="mx-3 mx-lg-0 mr-lg-5 mb-4">
                  <h5>
                    <a target="_blank" href="https://iconecwave.com/iConec-Wave-website-Privacy-Policy-2020.pdf" style={{ textDecoration: 'underline' }}>{translation["S7Header6"].label}</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <form className="card card-body shadow" onSubmit={sendEmail}>
                <div className="form-row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input className="form-control" value={state.name} onChange={handleChange} name="name" type="text" required placeholder="Name (*)"></input>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input type="email" name="email" className="form-control" id="planner-email"
                        placeholder="you@website.com" value={state.email} onChange={handleChange} required />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input type="tel" name="phone" className="form-control" id="planner-email"
                        placeholder="Phone" onChange={handleChange} value={state.phone} required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea name="desc" onChange={handleChange} value={state.desc} id="planner-message" rows="5" className="form-control" placeholder="How can we help?" required>
                  </textarea>
                </div>
                <div className="d-flex flex-column flex-wrap flex-md-row justify-content-between align-items-center">
                  {state.emailSent &&
                    <div className="alert alert-success w-80 my-md-3" role="alert">
                      Thanks, a member of our team will be in touch shortly.
                    </div>
                  }
                  <label></label>
                  <button className="btn btn-primary btn-loading" type="submit">
                    <span>Send</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="divider position-absolute bottom bg-primary-3"></div>
      </section>
    </>
  )
}

export default ReachUs;