function Makers({ avatar, name, job, experience, special, url }) {

  return (
    <div className="col-sm-12 bg-white makers-style mb-2">
      <div className="d-flex flex-column p-2">
        <img src={avatar} alt="profile image"
          className="avatar avatar-md mb-4 align-self-center" />
        <h5>{name}</h5>
        <span className="mb-2">{job}</span>
        <span className="mb-2">{experience}</span>
        <span className="mb-2">{special}</span>
        <a href={url}>More</a>
      </div>
    </div>
  )
}

export default Makers;