import { Parallax } from "react-parallax";

function ParallaxBox({ image, title, description }) {

  return (
    <div data-overlay className="bg-primary-3 jarallax text-white" >
      <Parallax
        strength={200}
        bgImage={image}
        bgImageStyle={{
          objectFit: 'cover',
          width: '100%',
          height: '130%',
        }}
        bgClassName="jarallax-img opacity-30">
        <section className="pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-xl-8 col-lg-10 col-md-11">
                <h1 className="display-5">{title}</h1>
                <span className="f-size28">{description}</span>
                <div className="d-flex flex-column flex-sm-row justify-content-center mt-4 mt-md-5">
                </div>
              </div>
            </div>
          </div>
          <div className="divider divider-bottom bg-white"></div>
        </section>
      </Parallax>
    </div>

  )
}

export default ParallaxBox;