
import React, { useEffect, useState } from 'react';
import getTranslation from './utils/translation';

import { NavBar } from './components/navbar';
import DigitalMain from './components/main';
import DigitalDiagnostics from './components/diagnostics';
import DigitalAdvisory from './components/advisory';
import DigitalExecution from './components/execution';
import Testimonials from './components/testimonials';
import Customers from './components/customers';
import DigitalInsights from './components/insights';
import ReachUs from './components/contact';
import Footer from './components/footer';

function App() {

  const [translation, setTranslation] = useState();
  const [inProgress, setInprogress] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const translation = await getTranslation();
      setTranslation(translation);
    }

    fetchData();
  }, [])


  if (!translation) {
    return null;
  } else {
    return (
      <div className="App">
        <NavBar />
        <DigitalMain translation={translation} />
        <DigitalDiagnostics translation={translation} />
        <DigitalAdvisory translation={translation} />
        <DigitalExecution translation={translation} />
        <Testimonials translation={translation} />
        <Customers translation={translation} />
        <DigitalInsights translation={translation} />
        <ReachUs translation={translation} />
        <Footer />
      </div>
    );
  }


}

export default App;
