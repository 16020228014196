function AltTestimonials({ avatar, name, job, description }) {

  return (
    <div className="carousel-cell">
      <div className="d-flex flex-column pt-5 no-gutters o-hidden">
        <div className="position-relative col-lg-10 d-flex align-items-sm-center">
          <img className="card-img-top h-100 avatar"
            src={avatar} />
          <div className="d-flex flex-column ml-3">
            <strong>{name}</strong>
            <span>{job}</span>
          </div>
        </div>
        <div className="pt-4 col-lg">
          <p className="p-0 border-0 text-body f-size18" dangerouslySetInnerHTML={{ __html: description }}>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AltTestimonials;