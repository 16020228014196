import React from 'react';
import S3Modular from '../assets/img/iconecwave/S3Modular.png';
import S3Fast from '../assets/img/iconecwave/S3Fast.png';
import S2LeadingPracticeAnalysis from '../assets/img/iconecwave/S2LeadingPracticeAnalysis.png';
import S2ImprovementPortfolio from '../assets/img/iconecwave/S2ImprovementPortfolio.png';
import S2ImprovementRoadmap from '../assets/img/iconecwave/S2ImprovementRoadmap.png';
import S2SkillRequirements from '../assets/img/iconecwave/S2SkillRequirements.png';
import S2PlatformAndCommunity from '../assets/img/iconecwave/S2PlatformAndCommunity.png';
import S2SeekingGlass from '../assets/img/iconecwave/S2SeekingGlass.png';
import S2PathForward from '../assets/img/iconecwave/S2PathForward.png';
import S2Validation from '../assets/img/iconecwave/S2Validation.png';
import S2GoFaster from '../assets/img/iconecwave/S2GoFaster.png';
import S2OptimizeInvestment from '../assets/img/iconecwave/S2OptimizeInvestment.png';
import S2Rocket from '../assets/img/iconecwave/S2Rocket.png';
import hero2Image from '../assets/img/iconecwave/S2BannerGetDigitalDirection.jpg';
import ParallaxBox from './partials/parallax-box';

const DigitalDiagnostics = ({ translation }) => {

  const ParallaxBg = [
    {
      image: hero2Image,
      title: translation["S2Title"].label,
      description: translation["S2SubTitle"].label
    }
  ]

  return (
    <>
      <div id="diagnostics">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>

      <section className="pt-5">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <div className="lead">{translation["S2SectionIntro"].label}</div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-6 col-lg-6">
              <div>
                <img src={S3Modular} alt="Skill Requirements" className="mb-4" />
                <h5 className="d-flex align-items-sm-center justify-content-center">{translation["S2Header1"].label}</h5>
                <p className="font-weight-light">
                  {translation["S2Header1Text"].label}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div>
                <img src={S3Fast} alt="Skill Requirements" className="mb-4" />
                <h5 className="d-flex align-items-sm-center justify-content-center">{translation["S2Header2"].label}</h5>
                <p className="font-weight-light">
                  {translation["S2Header2Text"].label}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <div>
                <span className="p-1 rounded-circle circle-style">1</span>
                <h5 className="align-items-sm-center mt-3 text-center">{translation["S2Header3"].label}</h5>
                <img src={S2LeadingPracticeAnalysis} alt="Leading Pratice Analysis" className="mb-4 w-100" />
                <p className="font-weight-light text-center">
                  {translation["S2Header3Text"].label}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <div>
                <span className="p-1 rounded-circle circle-style">2</span>
                <h5 className="align-items-sm-center mt-3 text-center">{translation["S2Header4"].label}</h5>
                <img src={S2ImprovementPortfolio} alt="Improvement Portfolio" className="mb-4 w-100" />
                <p className="font-weight-light text-center">
                  {translation["S2Header4Text"].label}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div>
                <span className="p-1 rounded-circle circle-style">3</span>
                <h5 className="align-items-sm-center mt-3 text-center">{translation["S2Header5"].label}</h5>
                <img src={S2ImprovementRoadmap} alt="Improvement Roadmap" className="mb-4 w-100" />
                <p className="font-weight-light text-center">
                  {translation["S2Header5Text"].label}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div>
                <span className="p-1 rounded-circle circle-style">4</span>
                <h5 className="align-items-sm-center mt-3 text-center">{translation["S2Header6"].label}</h5>
                <img src={S2SkillRequirements} alt="Skill Requirements" className="mb-4 w-100" />
                <p className="font-weight-light text-center">
                  {translation["S2Header6Text"].label}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-6">
              <div className="row align-items-center flex-column">
                <div className="col-sm-2 mb-3 mb-sm-3 mobile-center">
                  <img src={S2PlatformAndCommunity} alt="Platform And Community" className="img-fluid rounded" />
                </div>
                <div className="col">
                  <p className="font-weight-bolder">
                    {translation["S2Header7"].label}
                  </p>
                  <p className="font-weight-light">
                    {translation["S2Header7Text-1"].label}
                    <br />
                    {translation["S2Header7Text-2"].label}
                  </p>
                  <strong>
                    {translation["S2Header8"].label}
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mobile-mt-10">
              <div className="row align-items-center flex-column">
                <div className="col-sm-2 mb-3 mb-sm-3 mobile-center">
                  <img src={S2SeekingGlass} alt="Seeking Glass" className="img-fluid rounded" />
                </div>
                <div className="col d-flex flex-column">
                  <p className="font-weight-bolder">{translation["S2Header9"].label}</p>
                  <ul className="diagnostics-list p-0 mb-3 align-items-sm-center list-unstyled">
                    <li className="align-items-sm-center">
                      <img src={S2PathForward} alt="" className="w32" />
                      <span className="font-weight-light">{translation["S2Header9-Text1"].label}</span>
                    </li>
                    <li className="align-items-sm-center">
                      <img src={S2Validation} alt="Validation" className="w32" />
                      <span className="font-weight-light">{translation["S2Header9-Text2"].label}</span>
                    </li>
                    <li className="align-items-sm-center">
                      <img src={S2GoFaster} alt="Go Faster" className="w32" />
                      <span className="font-weight-light">{translation["S2Header9-Text3"].label}</span>
                    </li>
                    <li className="align-items-sm-center">
                      <img src={S2OptimizeInvestment} alt="Optimeze Investment" className="w32" />
                      <span className="font-weight-light">{translation["S2Header9-Text4"].label}</span>
                    </li>
                  </ul>
                  <p className="font-weight-bolder">{translation["S2Header10"].label}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <a className="text-center learn-btn-down" href="https://iconecwave.com/DxPathfinder Digital Assessment Brochure.pdf" target="_blank">Download Brochure</a>
          </div>
        </div>
      </section>
      <div className="footer-tag">
        <div className="container d-flex align-items-center justify-content-center">
          <img className="mr-4" src={S2Rocket} alt="" />
          <p>
            {translation["S2FooterText"].label}
          </p>
        </div>
      </div>
    </>
  )
}

export default DigitalDiagnostics;