import React from 'react';
import Slider from 'react-slick';

import S3Strategy from '../assets/img/iconecwave/S3Strategy.png';
import S3Expert from '../assets/img/iconecwave/S3Expert.png';
import S3SaveTime from '../assets/img/iconecwave/S3SaveTime.png';
import S3ReduceRisk from '../assets/img/iconecwave/S3ReduceRisk.png';
import S5ButtonLeft from '../assets/img/iconecwave/S5ButtonLeft.png';
import S5ButtonRight from '../assets/img/iconecwave/S5ButtonRight.png';
import AvatarUmit from '../assets/img/iconecwave/umit-karabudak.png';
import AvatarJulide from '../assets/img/iconecwave/julide-atasayar.png';
import AvatarTayfun from '../assets/img/iconecwave/tayfun-yasar.png';
import AvatarOsetya from '../assets/img/iconecwave/osetya-carman.png';
import AvatarSerkan from '../assets/img/iconecwave/serkan-sepil.png';
import AvatarNeslisah from '../assets/img/iconecwave/neslisah-kula.png';
import AvatarFirat from '../assets/img/iconecwave/firat-acar.png';
import AvatarInessa from '../assets/img/iconecwave/inessa-miller.png';


import hero3Image from '../assets/img/iconecwave/S3BannerGetDigitalExpertise.png';
import Makers from './partials/makers';
import ExpertiseBox from './partials/expertise-box';
import ParallaxBox from './partials/parallax-box';

const NextArrow = ({ className, style, onClick }) => <img style={{ ...style, right: "-50px" }} onClick={onClick} className={className} src={S5ButtonRight} />
const PrevArrow = ({ className, style, onClick }) => <img style={{ ...style, left: "-50px" }} onClick={onClick} className={className} src={S5ButtonLeft} />
const NextArrowMobile = ({ className, style, onClick }) => <img style={{ ...style, right: "-28px" }} onClick={onClick} className={className} src={S5ButtonRight} />
const PrevArrowMobile = ({ className, style, onClick }) => <img style={{ ...style, left: "-30px" }} onClick={onClick} className={className} src={S5ButtonLeft} />

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  slidesPerRow: 2,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        nextArrow: <NextArrowMobile />,
        prevArrow: <PrevArrowMobile />,
      }
    }
  ]
};

const DigitalAdvisory = ({ translation }) => {

  const ExpertiseContent = [
    {
      avatar: 'https://dxpathfinder.com/assets/img/profile/PatrickMiller.png',
      name: translation["Team1.name"].label,
      job: translation["Team1.title"].label,
      experience: translation["Team1.experience"].label,
      special: translation["Team1.specialization"].label,
      url: translation["Team1.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/JoyceVanKerckhove.png",
      name: translation["Team2.name"].label,
      job: translation["Team2.title"].label,
      experience: translation["Team2.experience"].label,
      special: translation["Team2.specialization"].label,
      url: translation["Team2.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/NicVanMier.png",
      name: translation["Team3.name"].label,
      job: translation["Team3.title"].label,
      experience: translation["Team3.experience"].label,
      special: translation["Team3.specialization"].label,
      url: translation["Team3.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/AnneliesLoosveldt.png",
      name: translation["Team4.name"].label,
      job: translation["Team4.title"].label,
      experience: translation["Team4.experience"].label,
      special: translation["Team4.specialization"].label,
      url: translation["Team4.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/FredericMartin.png",
      name: translation["Team5.name"].label,
      job: translation["Team5.title"].label,
      experience: translation["Team5.experience"].label,
      special: translation["Team5.specialization"].label,
      url: translation["Team5.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/LorraineBaldwin.png",
      name: translation["Team6.name"].label,
      job: translation["Team6.title"].label,
      experience: translation["Team6.experience"].label,
      special: translation["Team6.specialization"].label,
      url: translation["Team6.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/TimSheridan.png",
      name: translation["Team7.name"].label,
      job: translation["Team7.title"].label,
      experience: translation["Team7.experience"].label,
      special: translation["Team7.specialization"].label,
      url: translation["Team7.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/ArzuBalci.png",
      name: translation["Team8.name"].label,
      job: translation["Team8.title"].label,
      experience: translation["Team8.experience"].label,
      special: translation["Team8.specialization"].label,
      url: translation["Team8.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/SergeBeser.png",
      name: translation["Team9.name"].label,
      job: translation["Team9.title"].label,
      experience: translation["Team9.experience"].label,
      special: translation["Team9.specialization"].label,
      url: translation["Team9.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/EmmaHarrison.png",
      name: translation["Team10.name"].label,
      job: translation["Team10.title"].label,
      experience: translation["Team10.experience"].label,
      special: translation["Team10.specialization"].label,
      url: translation["Team10.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/TomQuets.png",
      name: translation["Team11.name"].label,
      job: translation["Team11.title"].label,
      experience: translation["Team11.experience"].label,
      special: translation["Team11.specialization"].label,
      url: translation["Team11.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/YanaDirkx.png",
      name: translation["Team12.name"].label,
      job: translation["Team12.title"].label,
      experience: translation["Team12.experience"].label,
      special: translation["Team12.specialization"].label,
      url: translation["Team12.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/BenoitBolly.png",
      name: translation["Team13.name"].label,
      job: translation["Team13.title"].label,
      experience: translation["Team13.experience"].label,
      special: translation["Team13.specialization"].label,
      url: translation["Team13.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/SannaPoyhonen.png",
      name: translation["Team14.name"].label,
      job: translation["Team14.title"].label,
      experience: translation["Team14.experience"].label,
      special: translation["Team14.specialization"].label,
      url: translation["Team14.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/PhilippeClercq.png",
      name: translation["Team15.name"].label,
      job: translation["Team15.title"].label,
      experience: translation["Team15.experience"].label,
      special: translation["Team15.specialization"].label,
      url: translation["Team15.more"].label
    },
    {
      avatar: "https://dxpathfinder.com/assets/img/profile/RonaldHermans.png",
      name: translation["Team16.name"].label,
      job: translation["Team16.title"].label,
      experience: translation["Team16.experience"].label,
      special: translation["Team16.specialization"].label,
      url: translation["Team16.more"].label
    },
    {
      avatar: AvatarUmit,
      name: translation["Team17.name"].label,
      job: translation["Team17.title"].label,
      experience: translation["Team17.experience"].label,
      special: translation["Team17.specialization"].label,
      url: translation["Team17.more"].label
    },
    {
      avatar: AvatarJulide,
      name: translation["Team18.name"].label,
      job: translation["Team18.title"].label,
      experience: translation["Team18.experience"].label,
      special: translation["Team18.specialization"].label,
      url: translation["Team18.more"].label
    },
    {
      avatar: AvatarTayfun,
      name: translation["Team19.name"].label,
      job: translation["Team19.title"].label,
      experience: translation["Team19.experience"].label,
      special: translation["Team19.specialization"].label,
      url: translation["Team19.more"].label
    },
    {
      avatar: AvatarOsetya,
      name: translation["Team20.name"].label,
      job: translation["Team20.title"].label,
      experience: translation["Team20.experience"].label,
      special: translation["Team20.specialization"].label,
      url: translation["Team20.more"].label
    },
    {
      avatar: AvatarSerkan,
      name: translation["Team21.name"].label,
      job: translation["Team21.title"].label,
      experience: translation["Team21.experience"].label,
      special: translation["Team21.specialization"].label,
      url: translation["Team21.more"].label
    },
    {
      avatar: AvatarNeslisah,
      name: translation["Team22.name"].label,
      job: translation["Team22.title"].label,
      experience: translation["Team22.experience"].label,
      special: translation["Team22.specialization"].label,
      url: translation["Team22.more"].label
    },
    {
      avatar: AvatarFirat,
      name: translation["Team23.name"].label,
      job: translation["Team23.title"].label,
      experience: translation["Team23.experience"].label,
      special: translation["Team23.specialization"].label,
      url: translation["Team23.more"].label
    },
    {
      avatar: AvatarInessa,
      name: translation["Team24.name"].label,
      job: translation["Team24.title"].label,
      experience: translation["Team24.experience"].label,
      special: translation["Team24.specialization"].label,
      url: translation["Team24.more"].label
    }
  ];

  const ExpertiseContentBox = [
    {
      image: S3Strategy,
      title: translation['S3Header1'].label,
      description: translation['S3Header1Text'].label,
    },
    {
      image: S3Expert,
      title: translation['S3Header2'].label,
      description: translation['S3Header2Text'].label,
    },
    {
      image: S3SaveTime,
      title: translation['S3Header3'].label,
      description: translation['S3Header3Text'].label,
    },
    {
      image: S3ReduceRisk,
      title: translation['S3Header4'].label,
      description: translation['S3Header4Text'].label,
    }
  ];

  const ParallaxBg = [
    {
      image: hero3Image,
      title: translation["S3Title"].label,
      description: translation["S3SubTitle"].label
    }
  ]

  return (
    <>
      <div id="advisory">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <div className="lead">{translation["S3SectionIntro"].label}</div>
            </div>
          </div>
          <div className="row text-center services-grid">
            {
              ExpertiseContentBox.map((value, key) =>
                <ExpertiseBox
                  key={key}
                  image={value.image}
                  title={value.title}
                  description={value.description}
                />
              )
            }
          </div>
          <div className="text-center mt-5">
            {/* <a className="text-center learn-btn-down" href="#">Learn More</a> */}
          </div>
        </div>
      </section>
      <section className="bg-yellow p-4">
        <div className="container">
          <div className="row d-flex flex-column align-items-sm-center">
            <h1 className="mt-3 mb-5 mobile-center">{translation["S3Header5"].label}</h1>
            <div className="col-xl-12 col-lg-12 mb-5">
              <Slider {...settings}>
                {ExpertiseContent.map((value, key) =>
                  <Makers
                    key={key}
                    avatar={value.avatar}
                    name={value.name}
                    job={value.job}
                    experience={value.experience}
                    special={value.special}
                    url={value.url}
                  />
                )}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-tag">
        <div className="container">
          <p>
            {translation["S3FooterText"].label}
          </p>
        </div>
      </div>
    </>
  )
}

export default DigitalAdvisory;