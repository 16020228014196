
function getTranslation(){
  let url = "https://sheets.googleapis.com/v4/spreadsheets"
  let sheetId = "1H0UWgRiOMJnB-9iNmB0ckHEHBlRI-LgFsoSpxwsM_dE";
  let key = "AIzaSyB2nrG_WXAJqsU8n2QQ2F99FP7NS9A8OkU"
  let sheets = ["Labels", "Team", "Testimonials"]
  let API = `${url}/${sheetId}/values:batchGet?${sheets.map(val => "ranges=" + val + "&").join('')}majorDimension=ROWS&key=${key}`;

  return fetch(API).then(response => response.json()).then(data => {
    let batchRowValues = [...data.valueRanges[0].values, ...data.valueRanges[1].values, ...data.valueRanges[2].values];
    const rows = {};
    for (let i = 1; i < batchRowValues.length; i++) {
      rows[batchRowValues[i][0]] = {
        label: batchRowValues[i][1],
        icon: batchRowValues[i][2]
      }
    }
    
    return Promise.resolve(rows);
  });
}

export default getTranslation;