import React from 'react';
import S1DigitalDiagnostic from '../assets/img/iconecwave/S1DigitalDiagnostic.png';
import S1AdvisoryServices from '../assets/img/iconecwave/S1AdvisoryServices.png';
import S1DigitalExecution from '../assets/img/iconecwave/S1DigitalExecution.png';
import hero1Image from '../assets/img/iconecwave/S1BannerBusinessModernizationServices.png';
import TitleBox from './partials/title-box';
import ParallaxBox from './partials/parallax-box';



const DigitalMain = ({ translation }) => {

  const DigitalMainContent = [
    {
      title: translation["S1Header1"].label,
      description: translation["S1Header1Text"].label,
      image: S1DigitalDiagnostic,
      url: "#diagnostics"
    },
    {
      title: translation["S1Header2"].label,
      description: translation["S1Header2Text"].label,
      image: S1AdvisoryServices,
      url: "#advisory"
    },
    {
      title: translation["S1Header3"].label,
      description: translation["S1Header3Text"].label,
      image: S1DigitalExecution,
      url: "#execution"
    }
  ];

  const ParallaxBg = [
    {
      image: hero1Image,
      title: translation["S1Title"].label,
    }
  ]


  return (
    <>
      {ParallaxBg.map((value, key) =>
        <ParallaxBox
          key={key}
          image={value.image}
          title={value.title}
        />
      )}
      <section className="pt-5">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <div className="lead">{translation["S1SectionIntro"].label}</div>
            </div>
          </div>
          <div className="row text-center">

            {DigitalMainContent.map((value, key) =>
              <TitleBox
                key={key}
                image={value.image}
                title={value.title}
                description={value.description}
                url={value.url}
              />
            )}
          </div>
        </div>
      </section>
      <div className="footer-tag">
        <div className="container">
          <p>
            {translation["S1FooterText"].label}
          </p>
        </div>
      </div>
    </>
  )
}

export default DigitalMain;