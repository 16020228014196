import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll'
import logo from '../assets/img/iconecwave/logo.png';


export const NavBar = () => {

  const [ navbarClass, setNavbarClass ] = useState("");
  var offset = -103;

  useEffect(() => {
    const content = document.getElementsByTagName("html")[0];
    document.addEventListener('scroll', (e) => {
      var position = content.scrollTop;
      
      if(position > 20){
        setNavbarClass("position-fixed scrolled")
      }else{
        setNavbarClass()
      }

    })

  }, [] )

  return (
    <div className="navbar-container">
      <nav className={`navbar navbar-expand-lg navbar-dark ${navbarClass}`} data-overlay data-sticky="top">
        <div className="container">
          <Link to="app" className="navbar-brand navbar-brand-dynamic-color fade-page" href="">
            <img alt="iconecwave" src={logo} width={60} />
          </Link>
          <div className="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-end" id="navigation-menu">
            <ul className="navbar-nav my-3 my-lg-0">
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#diagnostics" role="button">
                  <Link
                    activeClass="active"
                    to="diagnostics" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={600}>
                    Diagnostics
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#advisory" role="button">
                  <Link
                    activeClass="active"
                    to="advisory" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={1000}>
                    Advisory
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#execution" role="button">
                  <Link
                    activeClass="active"
                    to="execution" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={1000}>
                    Execution
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#testimonials" role="button">
                  <Link
                    activeClass="active"
                    to="testimonials" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={1000}>
                    Testimonials
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#insights" role="button">
                  <Link
                    activeClass="active"
                    to="insights" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={1500}>
                    Insights
                  </Link>
                </a>
              </li>
              <li className="nav-item">
                <a aria-expanded="false" aria-haspopup="true" className="nav-link nav-item"
                  data-toggle="dropdown-grid" href="#contact" role="button">
                  <Link
                    activeClass="active"
                    to="contact" spy={true}
                    smooth={true}
                    offset={offset}
                    duration={2000}>
                    Contact
                  </Link>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}