function VideoBox({ title, url }) {

  return (
    <div>
      <div className="flex-column">
        <div className="col-md-9 col-lg-8 col-xl-8">
          <h3 className="text-left">{title}</h3>
        </div>
        <div className="col-xl-8 col-lg-9 col-md-10">
          <iframe width="560" height="315" src={url}
            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture" allowFullScreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default VideoBox;