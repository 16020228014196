import React from 'react';
import S4POProxy from '../assets/img/iconecwave/S4POProxy.png';
import S4DedicatedTeam from '../assets/img/iconecwave/S4DedicatedTeam.png';
import S4DigitalOperations from '../assets/img/iconecwave/S4DigitalOperations.png';
import S4Focus from '../assets/img/iconecwave/S4Focus.png';
import S4BoostPerformance from '../assets/img/iconecwave/S4BoostPerformance.png';
import hero4Image from '../assets/img/iconecwave/S4BannerDigitalExecution.png';
import ExecutionBox from '../components/partials/execution-box';
import ParallaxBox from './partials/parallax-box';

const DigitalExecution = ({ translation }) => {
  const DigitalExecutionContent = [
    {
      title: translation["S4HeaderPOProxy"].label,
      description: translation["S4HeaderPOProxyText1"].label,
      text: translation["S4HeaderPOProxyText2"].label,
      image: S4POProxy,
      url: 'https://iconecwave.com/Agile Product Owner Proxy Brochure.pdf'
    },
    {
      title: translation["S4HeaderTeams"].label,
      description: translation["S4HeaderTeamsText1"].label,
      text: translation["S4HeaderTeamsText2"].label,
      image: S4DedicatedTeam,
      url: 'https://iconecwave.com/High Performance Digital Teams Brochure.pdf'
    },
    {
      title: translation["S4HeaderOperations"].label,
      description: translation["S4HeaderOperationsText1"].label,
      text: translation["S4HeaderOperationsText2"].label,
      image: S4DigitalOperations,
      url: 'https://iconecwave.com/Digital Operations Brochure.pdf'
    },
  ];
  const DigitalExecutionContentSub = [
    {
      title: translation["S4Header3"].label,
      description: translation["S4Header3Text"].label,
      image: S4BoostPerformance,
    },
    {
      title: translation["S4Header4"].label,
      description: translation["S4Header4Text"].label,
      image: S4Focus,
    }
  ];

  const ParallaxBg = [
    {
      image: hero4Image,
      title: translation["S4Title"].label,
      description: translation["S4SubTitle"].label
    }
  ]


  return (
    <>
      <div id="execution">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>
      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <div className="lead">{translation["S4SectionIntro"].label}</div>
            </div>
          </div>
          <div className="row text-center img-size">
            <div className="execution-services-grid mb-5">
              {DigitalExecutionContent.map((value, key) =>
                <ExecutionBox
                  key={key}
                  image={value.image}
                  title={value.title}
                  description={value.description}
                  text={value.text}
                  url={value.url}
                />
              )}
            </div>
            <div className="execution-sub-services-grid">
              {DigitalExecutionContentSub.map((value, key) =>
                <ExecutionBox
                  key={key}
                  image={value.image}
                  title={value.title}
                  description={value.description}
                  text={value.text}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="footer-tag">
        <div className="container">
          <p>
            {translation["S4FooterText"].label}
          </p>
        </div>
      </div>
    </>
  )
}

export default DigitalExecution;