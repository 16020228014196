function TitleBox({ image, title, description, url }) {

  return (
    <div className="col-md-6 col-lg-4 mobile-mb">
      <div className="mx-xl-4">
        <img src={image} alt="Treva logo" className="mb-4" />
        <h5>{title}</h5>
        <p className="font-weight-light">
          {description}
        </p>
        <a href={url} className="learn-btn">Learn More</a>
      </div>
    </div>
  )
}

export default TitleBox;