import React from 'react';
import IconArrowUp from '../assets/img/icons/interface/icon-arrow-up.svg'
import Dribbble from '../assets/img/icons/social/dribbble.svg'
import Twitter from '../assets/img/icons/social/twitter.svg'
import Github from '../assets/img/icons/social/github.svg'
import Facebook from '../assets/img/icons/social/facebook.svg'
import Google from '../assets/img/icons/social/google.svg'

import { Link } from 'react-scroll';

const Footer = () => {

  return (
    <>
      <footer className="bg-primary-3 text-white links-white pb-4 footer-1">
        <div className="container">
          <div className="row">
            <div className="col">

            </div>
          </div>
          <div
            className="row flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <div className="col-auto">
              <div className="d-flex flex-column flex-sm-row align-items-center text-small">

              </div>
            </div>
          </div>
        </div>
      </footer>

      <Link
        className="btn btn-primary rounded-circle btn-back-to-top mobile-back-to-top"
        activeClass="active"
        style={{ cursor: 'pointer' }}
        to="app"
        spy={true}
        smooth={true}
        offset={0}
        duration={600}>
        <img src={IconArrowUp} alt="Icon" className="icon" />
      </Link>
    </>
  )
}

export default Footer;