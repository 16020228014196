import React from 'react';

import hero6Image from '../assets/img/iconecwave/S6BannerInsights.png';
import ParallaxBox from './partials/parallax-box';
import VideoBox from './partials/video-box';

const DigitalInsights = ({ translation }) => {

  const ParallaxBg = [
    {
      image: hero6Image,
      title: translation["S6Title"].label,
      description: translation["S6SubTitle"].label
    }
  ];

  const VideoBoxComponent = [
    {
      title: translation["S6Header1"].label,
      url: "https://www.youtube.com/embed/OHT2AmxO6zA"
    },
    {
      title: translation["S6Header2"].label,
      url: "https://www.youtube.com/embed/rPcrqg7by-A"
    },
    {
      title: translation["S6Header3"].label,
      url: "https://www.youtube.com/embed/mOztjglYRuU"
    },
    {
      title: translation["S6Header4"].label,
      url: "https://www.youtube.com/embed/0L408n6IkwM"
    }
  ]

  return (
    <>
      <div id="insights">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>
      <section className="pt-5 grid-video">
        {VideoBoxComponent.map((value, key) =>
          <VideoBox
            key={key}
            title={value.title}
            url={value.url}
          />
        )}
      </section>
      <div className="footer-tag">
        <div className="container">
          <p>
            {translation["S6FooterText"].label}
          </p>
        </div>
      </div>
    </>
  )
}

export default DigitalInsights;