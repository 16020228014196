import React from 'react';
import Slider from "react-slick";

import hero5Image from '../assets/img/iconecwave/S5BannerTestimonials.png';
import AvatarBruno from '../assets/img/iconecwave/bruno-ballester.png';
import AvatarTim from '../assets/img/iconecwave/tim-willstead.png';
import AvatarLance from '../assets/img/iconecwave/lance-harlow.png';
import AvatarIan from '../assets/img/iconecwave/ian-shuttleworth.png';
import AvatarJonathan from '../assets/img/iconecwave/jon-grieb.png';
import AvatarSerge from '../assets/img/iconecwave/serge-beser.png';
import AvatarJohn from '../assets/img/iconecwave/john-pierson.png';
import AvatarPatrick from '../assets/img/iconecwave/patrick-abgrall.png';
import AvatarBillington from '../assets/img/iconecwave/john-billington.png';
import AvatarSudhi from '../assets/img/iconecwave/sudhi-sinha.png';
import AvatarBenjamin from '../assets/img/iconecwave/benjamin-moseley.png';
import AvatarEmre from '../assets/img/iconecwave/emre-mater.png';
import AltTestimonials from './partials/alt-testimonials';
import ParallaxBox from './partials/parallax-box';


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Testimonials = ({ translation }) => {


  const TestimonialsContent = [
    {
      name: translation["Testimonial1.name"].label,
      job: translation["Testimonial1.title"].label,
      description: translation["Testimonial1.quote"].label,
      avatar: AvatarBruno
    },
    {
      name: translation["Testimonial2.name"].label,
      job: translation["Testimonial2.title"].label,
      description: translation["Testimonial2.quote"].label,
      avatar: AvatarTim
    },
    {
      name: translation["Testimonial3.name"].label,
      job: translation["Testimonial3.title"].label,
      description: translation["Testimonial3.quote"].label,
      avatar: AvatarLance
    },
    {
      name: translation["Testimonial4.name"].label,
      job: translation["Testimonial4.title"].label,
      description: translation["Testimonial4.quote"].label,
      avatar: AvatarIan
    },
    {
      name: translation["Testimonial5.name"].label,
      job: translation["Testimonial5.title"].label,
      description: translation["Testimonial5.quote"].label,
      avatar: AvatarJonathan
    },
    {
      name: translation["Testimonial6.name"].label,
      job: translation["Testimonial6.title"].label,
      description: translation["Testimonial6.quote"].label,
      avatar: AvatarSerge
    },
    {
      name: translation["Testimonial7.name"].label,
      job: translation["Testimonial7.title"].label,
      description: translation["Testimonial7.quote"].label,
      avatar: AvatarJohn
    },
    {
      name: translation["Testimonial8.name"].label,
      job: translation["Testimonial8.title"].label,
      description: translation["Testimonial8.quote"].label,
      avatar: AvatarPatrick
    },
    {
      name: translation["Testimonial9.name"].label,
      job: translation["Testimonial9.title"].label,
      description: translation["Testimonial9.quote"].label,
      avatar: AvatarBillington
    },
    {
      name: translation["Testimonial10.name"].label,
      job: translation["Testimonial10.title"].label,
      description: translation["Testimonial10.quote"].label,
      avatar: AvatarSudhi
    },
    {
      name: translation["Testimonial11.name"].label,
      job: translation["Testimonial11.title"].label,
      description: translation["Testimonial11.quote"].label,
      avatar: AvatarBenjamin
    },
    {
      name: translation["Testimonial12.name"].label,
      job: translation["Testimonial12.title"].label,
      description: translation["Testimonial12.quote"].label,
      avatar: AvatarEmre
    }
  ];

  const ParallaxBg = [
    {
      image: hero5Image,
      title: translation["S5Title"].label,
      description: translation["S5SubTitle"].label
    }
  ]

  return (
    <>
      <div id="testimonials">
        {ParallaxBg.map((value, key) =>
          <ParallaxBox
            key={key}
            image={value.image}
            title={value.title}
            description={value.description}
          />
        )}
      </div>
      <section className="pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <div className="buttons-attached">
                <Slider {...settings}>
                  {TestimonialsContent.map((value, key) =>
                    <AltTestimonials
                      key={key}
                      avatar={value.avatar}
                      name={value.name}
                      job={value.job}
                      description={value.description}
                    />
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Testimonials;