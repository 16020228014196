function ExecutionBox({ image, title, description, text, url }) {

  return (
    <div className="col-md-6 col-lg-10">
      <div className="mx-xl-2 height-400">
          <img src={image} alt="Kyan logo" className="mb-4" />
        <h5>{title}</h5>
        <p className="font-weight-light">
          {description} <br /> <br />
          {text}
        </p>
      </div>
      <div className="text-center mt-1 brochure-btn-content">
        <a className="text-center brochure-btn-down" href={url} target="_blank">Download Brochure</a>
      </div>
    </div>
  )
}

export default ExecutionBox;