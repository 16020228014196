import React from 'react';

import rcLogo from "../assets/img/iconecwave/rc.png"
import boseLogo from "../assets/img/iconecwave/bose.png"
import goodyearLogo from "../assets/img/iconecwave/goodyear.png"
import jcLogo from "../assets/img/iconecwave/jc.png"
import sonyLogo from "../assets/img/iconecwave/sony.png"
import cocacolaLogo from "../assets/img/iconecwave/cocacola.png"

const Customers = ({ translation }) => {
  return (
    <>
      <section className=" center-md customers">
        <div className="col-md-11 d-flex flex-column ml-auto mr-auto">
          <div className="row d-flex align-items-sm-center text-center">
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img src={rcLogo} width="50%" alt="" />
            </div>
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img
                src={boseLogo}
                width="50%" alt="" />
            </div>
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img src={goodyearLogo} width="50%" alt="" />
            </div>
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img src={jcLogo} width="50%" alt="" />
            </div>
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img src={sonyLogo} width="50%" alt="" />
            </div>
            <div
              className="col-md logo-box bg-white text-center d-flex justify-content-center align-items-sm-center shadow-sm rounded-sm mx-4">
              <img
                src={cocacolaLogo}
                width="50%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="footer-tag">
        <div className="container d-flex">
          <div className="founder d-flex flex-column justify-content-center f-size16">
            <img className="w50 mb-2" src="https://dxpathfinder.com/assets/img/profile/PatrickMiller.png" alt="" />
            <span className="name">Patrick Miller</span>
            <span className="job">Founder & CEO</span>
          </div>
          <div>
            <p className="f-size22 text-left">
              <strong>{translation["S5FooterText-1"].label}</strong> <br />
              {translation["S5FooterText-2"].label} <br />
              {translation["S5FooterText-3"].label} <br />
              {translation["S5FooterText-4"].label}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customers;