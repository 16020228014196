function ExpertiseBox({ image, title, description, url }) {

  return (
    <div className="col-md-6 col-lg-10">
      <div className="mx-xl-4">
        <img src={image} alt="image" className="mb-4" />
        <h5>{title}</h5>
        <p className="font-weight-light">
          {description}
        </p>
      </div>
    </div>
  )
}

export default ExpertiseBox;